.nav {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}
.nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
}
.nav a {
    color:inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}
.nav li.active {
    background-color: #555;
}
.nav li:hover {
    background-color: #555;
}
.nav-logo {
    width: 128px
}
.nav-logo-text {
    font-size: 2rem;
}