* {
    box-sizing: border-box;
    font-family: 'Poppins',
    sans-serif;
}
.app-container{

}
.app-navbar{
}
.app-component{
}
.app-footer{

}

body {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

