.project-card
{
    padding: 25px;
}
#peak-conditions-project-card__title
{
    text-align: center;
}
a{
    text-align: center;
}