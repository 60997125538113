.footer-container{
    padding: 0;
    margin: 0;
    display: grid;
    background: lightslategray;
    grid-template-columns: 0.05fr 0.05fr 0.9fr ;
    grid-template-rows: 0.8fr 0.2fr ;
    grid-template-areas: "link-icon git-icon git-icon"
"sig sig sig";
}
.footer-signature{
    grid-area: sig;
    background: silver;
}
.footer-icon{
    size: 64px;
}
#linked-in-icon{
    grid-area: link-icon;
}
#github-icon{
    grid-area: git-icon;
}
