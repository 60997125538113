.wrapper {

}

a {
    color: white;
}
#title{
    background: #F1F4F4;
    box-shadow: 0px 5px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    height: 46px;
    text-align: center;
    position: absolute;
    width: 620px;

}

body {

}

.front,
.back{

}

.front {

}

.back {

}

.project-card {
    position: relative;
    align-items: center;
    background: #222;
    color: lightgreen;

    display: grid;
    justify-content: center;
    transition: all 1s linear;
    transform-style: preserve-3d;
}

.project-card:hover{
}

.project-card{
}
.project-header{
    text-align: center;
}