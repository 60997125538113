
.cv-contact-info {
    grid-area: contact;
}
.cv-container {
    display: grid;
    grid-gap: 0.6rem;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "title title contact"
                        "main main skills"
                        "main main tech"
                        "main main tools"
                        "work1 work2 work3";

    height: 100vh;
    padding: 20px;
    text-align: start;
}
.cv-header {
    grid-area: title;
    font-size: 2.5rem;
}

.cv-job-container {
    padding-bottom: 70px;
    padding-top: 100px;
    background-color: lightblue;

}
.cv-job-content {
    padding: 20px;
}

.cv-skills-list-container {
    grid-area: skills;
}
.cv-summary{
    grid-area: main;
    font-size: 1.3rem;
    padding-bottom: 40px;
}
.cv-tech-experience-container{
    grid-area: tech;
}
.cv-tools-experience-container{
    grid-area: tools;
}
#work1{
    grid-area: work1;
}
#work2{
    grid-area: work2;
}
#work3{
    grid-area: work3;

}